<template>
    <div></div>
</template>

<script>
export default {
    data:()=>({
       id:''     
    }),
    mounted(){
        this.id = this.$route.query.id
        this.$router.replace({ name: "Saledetail", query: {id: this.id} })
    }
}
</script>